import React, { FC, PropsWithChildren } from 'react';

import Transition, {
  TransitionStatus,
} from 'react-transition-group/Transition';

import styled from 'styled-components';

import { AnimationType } from '@shared/enums/animation-type';

type StyledComponentProps = {
  className: string;
  state: TransitionStatus;
  type: string;
  duration: number;
  onClick: () => void;
};

const AnimateContainer = styled.div<StyledComponentProps>`
  ${props =>
    props.type === AnimationType.Fade &&
    `
    transition: opacity ${props.duration}ms ease-in-out;
    opacity: ${props.state === 'entered' ? 1 : 0};
  `}

  ${props =>
    props.type === AnimationType.Slide &&
    `
    transition: all ${props.duration}ms;
    transform: translateY(${props.state === 'entered' ? '0%' : '100%'});
  `}

  ${props =>
    props.type === AnimationType.SlideUp &&
    `
    transition-property: all;
    transition-duration: ${props.duration}ms;
    transition-timing-function: cubic-bezier(0.17, 0.04, 0.03, 0.94);
    top: ${props.state === 'entered' ? 0 : 5}%;
    opacity: ${props.state === 'entered' ? 1 : 0};
  `}

  ${props =>
    props.type === AnimationType.SlideExtend &&
    `
    transition-property: all;
    transition-duration: ${props.duration}ms;
    transition-timing-function: cubic-bezier(0.17, 0.04, 0.03, 0.94);
    transform: translateY(${props.state === 'entered' ? '0%' : '100%'});
    opacity: ${props.state === 'entered' ? 1 : 0};
  `}
`;

type AnimationProps = {
  className?: string;
  in?: boolean;
  type?: AnimationType;
  duration?: number;
  onClick?: () => void;
};

const Animation: FC<PropsWithChildren<AnimationProps>> = ({
  duration,
  ...props
}) => {
  return (
    <Transition
      in={props.in}
      timeout={duration}
      unmountOnExit
      mountOnEnter
      appear
    >
      {state => (
        <AnimateContainer
          data-cy="animation-test"
          className={props.className}
          state={state}
          type={props.type}
          duration={duration}
          onClick={props.onClick}
        >
          {props.children}
        </AnimateContainer>
      )}
    </Transition>
  );
};

Animation.defaultProps = {
  type: AnimationType.Fade,
  duration: 200,
};

export default Animation;
