import React, { PropsWithChildren, PureComponent } from 'react';

import styled from 'styled-components';

import { Align } from '@shared/enums/align';
import { Colors } from '@shared/interfaces/themes';
import { Transform } from '@shared/enums/transform';
import { breakpoint } from 'theme';
import { getStyle } from '@shared/markup/functions';

type TextProps = {
  className?: string;
  component?: any;
  color?: keyof Colors;
  verticalAlign?: string;
  align?: Align;
  transform?: Transform;
  lineHeight?: number | string;
  weight?: 300 | 400 | 600 | 'bold' | 'normal' | 'inherit';
  opacity?: number;
  size?: number;
  smSize?: number;
  xsSize?: number | string;
  mSize?: number;
  lgSize?: number;
  onClick?: () => void;
  isActive?: boolean;
  hoverColor?: string;
  dangerouslySetInnerHTML?: { __html: string };
  style?: { marginBottom: number };
  tabIndex?: number;
  ariaLabel?: string;
};

class Text extends PureComponent<PropsWithChildren<TextProps>> {
  static defaultProps = {
    size: 1,
    align: Align.Left,
    transform: Transform.None,
    weight: 'inherit',
    lineHeight: 'inherit',
    verticalAlign: 'inherit',
    component: 'span',
  };

  render() {
    const { component, ...props } = this.props;

    return <StyledText data-cy="text-test" as={component} {...props} />;
  }
}

export default Text;

const StyledText = styled.span<TextProps>`
  padding: 0;
  margin: 0;

  color: ${props =>
    props.color ? props.theme.colors[props.color] : 'inherit'};
  text-align: ${props => props.align};
  text-transform: ${props => props.transform};
  font-weight: ${props => props.weight};
  line-height: ${props => props.lineHeight};
  vertical-align: ${props => props.verticalAlign};
  transition: color 0.2s;
  opacity: ${props => props.opacity};

  ${props => getStyle(props.size, 'font-size', 'em')}

  ${breakpoint.only('xs', 'sm')`
    ${props => getStyle(props.xsSize, 'font-size', 'em')}
  `}
  
  ${breakpoint.down('ltm')`
    ${props => getStyle(props.smSize, 'font-size', 'em')}
  `}

  ${breakpoint.only('m', 'ltLg')`
    ${props => getStyle(props.mSize, 'font-size', 'em')}
  `}

  ${breakpoint.up('lg')`
    ${props => getStyle(props.lgSize, 'font-size', 'em')}
  `}
`;
